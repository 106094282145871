<template>
    <v-card>
        <v-app-bar dense>
            <v-toolbar-title>
                Period Selection
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip v-if="editOptions" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="editOptions" @click.native="editOptions = !editOptions" slot="activator">
                        <v-icon small color="teal">far fa-edit</v-icon>
                    </v-btn>
                </template>
                <span>Edit</span>
            </v-tooltip>
            <v-tooltip v-if="!editOptions" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="!editOptions" @click.native="saveChanges()" slot="activator">
                        <v-icon small color="green">far fa-save</v-icon>
                    </v-btn>
                </template>
                <span>Save</span>
            </v-tooltip>
        </v-app-bar>
        <v-card-text>
            <v-flex xs12>
                <label class="head_label">Period</label>
            </v-flex>
            <v-flex xs12>
                <v-autocomplete color="primary"
                                solo
                                dense
                                :disabled="editOptions"
                                v-model="period"
                                :items="periods"></v-autocomplete>
            </v-flex>

            <v-flex xs12 v-if="period === 'Weekly'">
                <label class="head_label">Weekly</label>
            </v-flex>
            <v-flex xs12 v-if="period === 'Weekly'">
                <v-autocomplete color="primary"
                                solo
                                dense
                                :disabled="editOptions"
                                v-model="startDayOfWeek"
                                :items="daysOfWeek"></v-autocomplete>
            </v-flex>

            <v-flex xs12 v-if="period === 'Bi-Weekly'">
                <label class="head_label">Bi-Weekly</label>
            </v-flex>
            <v-flex xs12 v-if="period === 'Bi-Weekly'">
                <v-autocomplete color="primary"
                                solo
                                dense
                                :disabled="editOptions"
                                v-model="startDayOfWeek"
                                :items="daysOfWeek"></v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="period === 'Bi-Weekly'">
                <v-menu v-model="date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="startDateDayOfWeek"
                                      label="Start Date"
                                      prepend-icon="mdi-calendar"
                                      :disabled="editOptions"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="startDateDayOfWeek"
                                   @input="date = false"></v-date-picker>
                </v-menu>
            </v-flex>

            <v-flex xs12 v-if="period === 'Monthly'">
                <v-autocomplete color="primary"
                                solo
                                dense
                                :disabled="editOptions"
                                v-model="startDayMonth"
                                :items="daysOfMonth"></v-autocomplete>
            </v-flex>

            <v-flex xs12 v-if="period === 'Yearly'">
                <v-menu v-model="date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="startDateYear"
                                      label="Start Date"
                                      prepend-icon="mdi-calendar"
                                      :disabled="editOptions"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="startDateYear" @input="date = false"></v-date-picker>
                </v-menu>
            </v-flex>

            <!--Snackbar-->
            <v-snackbar v-model="snackbar"
                        left>
                {{ snackbarMessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn dark
                           text
                           v-bind="attrs"
                           @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <!--End Of Snackbar-->

        </v-card-text>
    </v-card>
</template>

<script>
    import { globalFunctions } from '../../../javascript/globalFunctions'

    import { HTTP } from '../../../api/httpCommon';

    export default {
        data() {
            return {
                saveOptions: false,
                editOptions: true,
                snackbarMessage: '',
                snackbar: '',
                periods: ['Weekly', 'Bi-Weekly', 'Monthly', 'Yearly'],
                period: 'Monthly',
                daysOfMonth: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
                daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                startDayOfWeek: 'Monday',
                date: false,
                startDateDayOfWeek: '',
                startDayMonth: '',
                startDateYear: '',
            }
        },
        methods: {
            saveChanges: function () {
                this.saveOptions = !this.saveOptions;
                this.editOptions = !this.editOptions;
                this.snackbar = true;
                this.snackbarMessage = 'Options Updated'

                this.updateSelectPeriod()
            },
            setDateIfMissing: function () {
                let date = globalFunctions.getCurrentDateForPicker()

                if (globalFunctions.isNullOrEmpty(this.startDateDayOfWeek)) {
                    this.startDateDayOfWeek = date
                }

                if (globalFunctions.isNullOrEmpty(this.startDayMonth)) {
                    this.startDayMonth = '1'
                }

                if (globalFunctions.isNullOrEmpty(this.startDateYear)) {
                    this.startDateYear = date
                }
            },
            updateSelectPeriod: function () {
                let selectPeriodSettings = {
                    period: '',
                    periodStartDayOfWeek: '',
                    periodStartDay: '',
                    periodStartDate: ''
                }

                selectPeriodSettings.period = this.period

                switch (this.period) {
                    case "Weekly":
                        selectPeriodSettings.periodStartDayOfWeek = this.startDayOfWeek
                        break;
                    case "Bi-Weekly":
                        selectPeriodSettings.periodStartDayOfWeek = this.startDayOfWeek
                        selectPeriodSettings.periodStartDate = this.startDateDayOfWeek
                        break;
                    case "Monthly":
                        selectPeriodSettings.periodStartDay = this.startDayMonth
                        break;
                    case "Yearly":
                        selectPeriodSettings.periodStartDate = this.startDateYear
                        break;
                }
                
                this.$store.commit('updateSelectPeriodInStore', selectPeriodSettings)
                this.updateAPIWIthNewInfo(selectPeriodSettings)
            },
            updateAPIWIthNewInfo: function (periodSettings) {
                HTTP.post('Period', periodSettings)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },
        beforeMount() {
            this.setDateIfMissing()
        },
    }
</script>